import getFirebaseAuth from 'actions/firebase/auth'
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'

const changePassword = async (currentPassword, password) => {
  const firebaseAuth = getFirebaseAuth()
  const { currentUser } = firebaseAuth
  const credential = EmailAuthProvider.credential(currentUser.email, currentPassword)

  return reauthenticateWithCredential(currentUser, credential).then(async () => updatePassword(currentUser, password))
}

export default changePassword
