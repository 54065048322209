import React, { memo } from 'react'
import NumberFormat from 'react-number-format'

export default memo(({ inputRef, value, format, ...props }) => {
  const replaceChars = format.replace(/[ #]/g, '')
  const onFocus = (e) => {
    const { target } = e
    if (target.value === '') {
      setTimeout(() => target.setSelectionRange(0, 0), 1)
    }
  }
  return (
    <NumberFormat
      {...props}
      format={format}
      value={value?.replace(new RegExp('^\\' + replaceChars, 'g'))}
      mask="_"
      allowEmptyFormatting
      onFocus={onFocus}
    />
  )
})
