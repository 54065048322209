import React, { forwardRef } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CustomPhoneInput from 'components/CustomPhoneInput'
// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js'
const useStyles = makeStyles(styles)

export default forwardRef(
  (
    { type, maxLength, inputProps, phone, selectAll, InputProps, InputLabelProps, variant = 'filled', ...props },
    ref
  ) => {
    const classes = useStyles()
    const { error, helperText } = props
    let onKeyPress
    let onFocus

    if (phone) {
      return <CustomPhoneInput {...props} />
    }

    if (selectAll) {
      onFocus = (e) => {
        const { target } = e
        setTimeout(() => target.select(), 1)
      }
    }

    if (type === 'number' && maxLength) {
      const len = maxLength
      onKeyPress = (e) => {
        const {
          target: { value },
          charCode,
        } = e

        if (charCode > 47 && charCode < 58 && value.length === len) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
      maxLength = undefined
    }

    return (
      <TextField
        type={type}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        variant={variant}
        {...props}
        ref={ref}
        error={!!error}
        className={(props.className ?? '') + ' ' + classes.formControl}
        helperText={error ? error.message : helperText}
        inputProps={{ ...inputProps, maxLength }}
        InputLabelProps={{
          ...InputLabelProps,
          classes: {
            root: classes.labelRoot,
            asterisk: classes.labelAsterisk,
          },
        }}
        InputProps={{
          ...InputProps,
          classes: {
            disabled: classes.disabled,
          },
        }}
      />
    )
  }
)
