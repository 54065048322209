import { PhoneNumberUtil } from 'google-libphonenumber'
import * as yup from 'yup'

const phoneUtil = PhoneNumberUtil.getInstance()

export function validatePhoneNumber(value) {
  if (value) {
    try {
      const number = phoneUtil.parse(value.replace(/[^\d+]/g, ''))
      return phoneUtil.isPossibleNumber(number)
    } catch (e) {}
  }
  return false
}
yup.addMethod(yup.string, 'phone', function () {
  return this.test('phone', async (value) => validatePhoneNumber(value))
})

export default yup
