import firebaseConfig from 'config/firebase'
import { RECAPTCHA_SITE_KEY } from 'config/app'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from 'firebase/app-check'

let firebaseApp, appCheck

export async function getAppCheckToken() {
  const { token } = await getToken(appCheck)
  return token
}

const initFirebaseApp = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig)
    appCheck = initializeAppCheck(firebaseApp, { provider: new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY) })
  }

  return firebaseApp
}

export default initFirebaseApp
