import React, { forwardRef, memo, useState } from 'react'
import { InputAdornment } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import MaskInput from './maskInput'
import Select from './select'
import { PHONE_COUNTRIES } from 'config/app'
import { countries, getCountriesByCodes } from './countries'

export default memo(
  forwardRef(({ onChange, countryCodes, ...props }, ref) => {
    const [countryCode, setCountryCode] = useState(countryCodes?.[0] ?? PHONE_COUNTRIES[0])
    const onCountryChange = (e) => {
      setCountryCode(e.target.value)
    }

    let displayCountries = countries

    if (countryCodes) {
      displayCountries = getCountriesByCodes(countryCodes)
    }

    const getCountryFormat = (code) => displayCountries.find((c) => c.code === code)?.format

    const onValueChange = (e) => {
      const regex = /\s+|[^\d+]+/g
      let { value } = e.target
      value = value.replace(regex, '')
      const format = getCountryFormat(countryCode).replace(regex, '')
      return onChange(value === format ? '' : value)
    }

    return (
      <CustomInput
        {...props}
        onChange={onValueChange}
        InputProps={{
          inputComponent: MaskInput,
          inputProps: {
            format: displayCountries.find((c) => c.code === countryCode)?.format,
          },
          startAdornment: (
            <>
              {displayCountries.length > 1 && (
                <InputAdornment position="start">
                  <Select onChange={onCountryChange} value={countryCode} displayCountries={displayCountries} />
                </InputAdornment>
              )}
            </>
          ),
        }}
        ref={ref}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  })
)
