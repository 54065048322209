import { PHONE_COUNTRIES } from 'config/app'

const allCountries = [
  {
    name: 'Myanmar',
    code: 'mm',
    countryCode: '+95',
    format: '+95 9 ### ### ###',
  },
  { name: 'Singapore', code: 'sg', countryCode: '+65', format: '+65 #### ####' },
  { name: 'Thailand', code: 'th', countryCode: '+66', format: '+66 # #### ####' },
]

export const countries = allCountries.filter((c) => PHONE_COUNTRIES.includes(c.code))

export const getCountriesByCodes = (codes) => allCountries.filter((c) => codes.includes(c.code))
