import React, { memo } from 'react'
import { Select, MenuItem, Input } from '@material-ui/core'
import Flags from 'country-flag-icons/react/3x2'
import useStyles from './styles'

export default memo(({ label, error, variant = 'filled', displayCountries, ...props }) => {
  const classes = useStyles()

  return (
    <Select
      renderValue={(v) => {
        const CountryFlag = Flags[v.toUpperCase()]
        return <CountryFlag height={25} />
      }}
      input={<Input disableUnderline={true} />}
      variant={variant}
      {...props}
      classes={{ select: classes.select }}
    >
      {displayCountries.map((country) => {
        const CountryFlag = Flags[country.code.toUpperCase()]
        return (
          <MenuItem key={country.code} value={country.code}>
            <CountryFlag height={20} style={{ marginRight: 10 }} />
            {`${country.name} (${country.countryCode})`}
          </MenuItem>
        )
      })}
    </Select>
  )
})
