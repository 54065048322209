import { setHours, setMinutes, setSeconds } from 'date-fns'

const parseTime = (time) => {
  const [hour, minute] = time.split(':')
  return setHours(setMinutes(setSeconds(new Date(), 0), minute), hour)
}

export const companyId = process.env.GATSBY_COMPANY_ID ?? 'root'

export const BASE_API_URL = process.env.GATSBY_BASE_API_URL ?? ''

export const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY ?? ''
export const RECAPTCHA_VALIDATE_URL = process.env.GATSBY_RECAPTCHA_VALIDATE_URL ?? `${BASE_API_URL}/recaptcha/validate`

export const BOOKING_OTP_VERIFY_URL = process.env.GATSBY_BOOKING_OTP_VERIFY_URL ?? `${BASE_API_URL}/otp/verify`

export const BOOKING_PAYMENT_URL = process.env.GATSBY_BOOKING_PAYMENT_URL ?? `${BASE_API_URL}/pay-booking`

export const SLOT_LABEL = process.env.GATSBY_SLOT_LABEL ?? 'Slot'
export const FEE_LABEL = process.env.GATSBY_FEE_LABEL ?? 'Fee'
export const GROUP_LABEL = process.env.GATSBY_GROUP_LABEL ?? 'Group'

export const PHONE_COUNTRIES = (process.env.GATSBY_PHONE_COUNTRIES ?? '').split(',')

export const CURRENCY = process.env.GATSBY_CURRENCY ?? 'MMK'

export const LOCALE = process.env.GATSBY_LOCALE ?? 'mm-MM'

export const OTP_EXPIRED_SECONDS = process.env.GATSBY_OTP_EXPIRED_SECONDS ?? 60

export const FIELD_FILE = process.env.GATSBY_FIELD_FILE ?? 'fields.json'

export const OPEN_TIME = process.env.GATSBY_OPEN_TIME
  ? parseTime(process.env.GATSBY_OPEN_TIME)
  : setHours(setMinutes(setSeconds(new Date(), 0), 0), 7)
export const CLOSE_TIME = process.env.GATSBY_CLOSE_TIME
  ? parseTime(process.env.GATSBY_CLOSE_TIME)
  : setHours(setMinutes(setSeconds(new Date(), 0), 0), 19)
export const CALENDAR_TIME_STEP = parseInt(process.env.GATSBY_CALENDAR_TIME_STEP) ?? 15

export const SLOT_NOT_AVAILABLE = process.env.GATSBY_SLOT_NOT_AVAILABLE ?? 'N/A'

export const BOOKING_EDITABLE = process.env.GATSBY_BOOKING_EDITABLE === 'true' ?? true

export const NO_BANNER = process.env.GATSBY_NO_BANNER === 'true' ?? false

export const TIMEZONE = process.env.GATSBY_TIMEZONE ?? 'Asia/Yangon'

export const TIMEZONE_NOTE =
  process.env.GATSBY_TIMEZONE_NOTE ??
  'All slots are Myanmar Time <br/> Booking အချိန်များသည် မြန်မာစံတော်ချိန်ဖြစ်ပါသည်'

export const ANDROID_DOWNLOAD_CONFIRMATION = process.env.GATSBY_ANDROID_DOWNLOAD_CONFIRMATION
export const CONFIRMATION_AUTO_DOWNLOAD = process.env.GATSBY_CONFIRMATION_AUTO_DOWNLOAD === 'true'

export const paymentGateways = (process.env.GATSBY_PAYMENT_GATEWAYS ?? 'kbzpay,mpu,stripe,paypal').split(',')

export const DISPLAY_FORM = process.env.GATSBY_DISPLAY_FORM ?? 'form1'

export const TNC = process.env.GATSBY_TNC
