import getFirebaseApp from './index'
import {
  initializeAuth,
  connectAuthEmulator,
  browserSessionPersistence,
  onIdTokenChanged as onFBIdTokenChanged,
  onAuthStateChanged as onFBAuthStateChanged,
  RecaptchaVerifier as FBRecaptchaVerifier,
} from 'firebase/auth'

let auth
const getFirebaseAuth = () => {
  if (!auth) {
    const fb = getFirebaseApp()
    auth = initializeAuth(fb, {
      persistence: browserSessionPersistence,
    })

    if (location.hostname === 'localhost') {
      connectAuthEmulator(auth, 'http://localhost:4005')
    }
  }
  return auth
}

export const onIdTokenChanged = (callback) => onFBIdTokenChanged(getFirebaseAuth(), callback)

export const onAuthStateChanged = (callback) => onFBAuthStateChanged(getFirebaseAuth(), callback)

export const RecaptchaVerifier = (ref) => new FBRecaptchaVerifier(ref, {}, getFirebaseAuth())

export default getFirebaseAuth
